.services {
    height: 85vh;
    text-align: center;
   
}

.service-title { 
    margin-left: auto;
    margin-right: auto;
}

.service-container { 
    display: flex;
    margin-left: auto;
    margin-right: auto;
   
}



.service-left-side {

  margin-top: 30px;
    display: flex;
    flex-direction: row;
   
}

.service-right-side {
    margin-top: 30px;
   
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.feature-column {
    display: flex;
    flex-direction: column;
   
}
