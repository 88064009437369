@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&family=Montserrat:wght@100&display=swap');

.ortega_navbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 2rem 6rem;
}

.ortega_navbar-links {
    font-family: 'Maven Pro', sans-serif;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ortega_navbar-links_logo {
    margin-right: 2rem;
    cursor: pointer;
}

.ortega_navbar-links_logo img{
   width: 180px;
   height: 70px;
   justify-content: space-evenly;
}

.ortega_navbar-links_facebook img{
    width: 60px;
    height: 45px;
    
   
}

.ortega_navbar-links_google img{
    width: 40px;
    height: 40px;
    margin-left: 20px;
}

.ortega_navbar-links_container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.ortega_navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.ortega_navbar-links_container p,
.ortega_navbar-menu_container p,
.ortega_navbar-menu_container p {
    color: black;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.ortega_navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.ortega_navbar-menu svg {
    cursor: pointer;
}

.ortega_navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: white;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.ortega_navbar-menu_container p {
    margin: 1rem 0;
}

.icons >button{
    border-style: none; 
    justify-content: space-evenly;
    background-color: white;
    cursor: pointer;
}

.icons {
   display: flex;
  justify-content: space-evenly;
}


@media screen and (max-width: 1050px) {
    .ortega_navbar-links_container {
        display: none;
    }

    .ortega_navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
   .ortega_navbar {
       padding: 2rem 4rem;
   }
}

@media screen and (max-width: 550px) {
    .ortega_navbar {
        padding: 2rem;
    }

    .ortega_navbar-sign {
        display: none;
    }

    .ortega_navbar-menu_container {
        top: 20px;
    }

    .ortega_navbar-menu_container-links-sign {
        display: block;
    }
    
}
