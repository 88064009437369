* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    /* background: linear-gradient(90deg, rgb(236, 56, 56) 0%, rgb(20, 86, 230) 100%);; */
}

a {
    color: unset;
    text-decoration: none;
}

.gradient_bg {
 background: white;
}

.section_padding {
    padding: 4rem 6rem;
}

.section_margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}



 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px){
      .section_padding {
          padding: 4rem 2rem;
      }
      
      .section_margin {
          margin: 4rem 2rem;
      }
  }