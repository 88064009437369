@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&family=Montserrat:wght@100&display=swap');

.Header {
    height: 55vh;
    
}

.Header-BodyContainer {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    
    
}

.Header-BodyContainer-Left { 
    width: 800px;
   height: 400px;
   margin-left: auto;
}

.Header-BodyContainer-Left >h1 {
    font-family: 'Maven Pro', sans-serif;
    margin-top: auto;
    margin-bottom: 25px;
    color: black;
}

.Header-BodyContainer-Left >p {
    font-family: 'Maven Pro', sans-serif;
    margin-bottom: 18px;
    color: black;
}

.Header-BodyContainer-Left-ButtonsContainer {
    width: 60%;
    display: flex;
 
}

.Header-BodyContainer-Left-ButtonsContainer >button:hover {
    color: white;
    background-color: blue;
    transition: all 200ms ease-in-out;
    border-radius: 50px;
    padding: 10px;
}

.Header-BodyContainer-Left-ButtonsContainer >button {
    font-size: 20px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 300;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    border-style: none;
    border-radius: 50px;
    padding: 10px;
    background-color: #f14d4d;
    cursor: pointer;  
    
}

.Header-van {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  
    align-content: center;
    align-items: center;

}

.Header-van >img{
    border-radius: 50px;

}

.Header-van >svg {
    
    height: 350px;
    width: 650px;
}

.Header-BodyContainer-Right {
 display: flex;
 width: 500px;
 height: 300px;
}

.Header-red {
    align-content: flex-end;
    position: relative;
    margin-bottom: 50px;
    z-index: 2;
    border: 1px;
    
}


.Header-blue {
    position: relative;
    z-index: 1;
}

.App-bluePlacement {
    align-content: flex-end;
    position: absolute;
}



